<template>
  <b-container class="col">
    <b-overlay :show="isLoading">
      <app-collapse accordion>
        <app-collapse-item
          v-for="(item, index) in items"
          :key="index"
          class="my-2"
          title=""
          :subtitle="''"
          variant="warning"
        >
          <template #header>
            <b-button variant="primary"> F {{ item.area }} </b-button>
          </template>
          <MyTable
            :rows="item.postal"
            :columns="columns"
            :is-loading="isLoading"
          >
            <template v-slot="{ props }">
              <div v-if="props.column.field === 'category'">
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="handleModal(props.row, item.area)"
                >
                  {{ props.row.category }}
                </b-button>
              </div>

              <div v-else-if="props.column.field === 'zone'">
                <b-badge variant="primary" class="w-100 badge-padding">
                  {{ props.row.zone }}
                </b-badge>
              </div>

              <div v-else-if="props.column.field === 'range'">
                {{ `${props.row.start_range} - ${props.row.end_range}` }}
              </div>

              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>
          </MyTable>
        </app-collapse-item>
      </app-collapse>
    </b-overlay>

    <b-modal ref="modal" :title="`Quick View - ${form.category}`" hide-footer>
      <b-form>
        <b-form-group label="Category">
          <b-form-input v-model="form.category" readonly />
        </b-form-group>

        <b-form-group label="Zone">
          <b-form-input v-model="form.zone" readonly />
        </b-form-group>

        <b-form-group label="Area">
          <b-form-input v-model="form.area" readonly />
        </b-form-group>

        <b-form-group label="State">
          <b-form-input v-model="form.state" readonly />
        </b-form-group>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Starting Range">
              <b-form-input v-model="form.start_range" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Ending Range">
              <b-form-input v-model="form.end_range" readonly />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Branch State">
          <b-form-input v-model="area" readonly />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "PostalCoverage",
  components: {
    MyTable,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      columns: [
        {
          label: "Postcode",
          field: "postcode",
          thClass: "align-middle",
          tdClass: "text-center align-middle",
          sortable: false,
        },
        {
          label: "Area",
          field: "area",
          thClass: "align-middle",
          tdClass: "text-center align-middle",
          sortable: false,
        },
        {
          label: "State",
          field: "state",
          thClass: "align-middle",
          tdClass: "text-center align-middle",
          sortable: false,
        },
      ],
      items: [],
      isLoading: false,
      form: {
        category: "",
        zone: "",
        area: "",
        state: "",
        start_range: "",
        end_range: "",
      },
      area: "",
    };
  },
  created() {
    this.getCoverages();
  },
  methods: {
    async getCoverages() {
      this.isLoading = true;
      const response = await this.$http.get("postal_price_checker");
      if (!response.status === 200) {
        this.isLoading = false;
        this.$swal({
          title: "Error",
          text: "Sorry. Something went wrong in your request. Please try again.",
          icon: "error",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn-danger",
          },
        });
        return;
      }

      this.items = response.data.data;
      this.isLoading = false;
    },
    handleModal(item, area) {
      this.area = area;
      this.form = {
        category: item.category,
        zone: item.zone,
        area: item.area,
        state: item.state,
        start_range: item.start_range,
        end_range: item.end_range,
      };
      this.$refs.modal.show();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
</style>
